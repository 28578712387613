// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-city-js": () => import("./../../../src/pages/city.js" /* webpackChunkName: "component---src-pages-city-js" */),
  "component---src-pages-hata-js": () => import("./../../../src/pages/hata.js" /* webpackChunkName: "component---src-pages-hata-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-turkiye-tesekkurler-js": () => import("./../../../src/pages/turkiye/tesekkurler.js" /* webpackChunkName: "component---src-pages-turkiye-tesekkurler-js" */)
}

